import React, { useEffect } from "react";
import { Route, Switch, withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from 'layouts/auth-layout';
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from 'antd';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import WebLayout from "layouts/web-layout";
import Forget from "./app-views/forget_password";
import { useLocation } from "react-router-dom";
import axios from "axios";

export const Views = (props) => {
  const { locale, direction } = props;
  const currentAppLocale = AppLocale[locale];
  const location = useLocation();
  const history = useHistory();

  useBodyClass(`dir-${direction}`);

  useEffect(() => {
    const instance = axios.create();

    instance.interceptors.response.use(
      response => response,
      function (error) {
        const errorMessage = error.response?.data.message;
        const pathName = location.pathname;

        const redirectTo = (path) => {
          if (location.pathname !== path) {
            sessionStorage.removeItem("token");
            window.location.href = path;
          }
        };

        if (error.response?.data === "Unauthorized" && pathName !== "/auth/login-2") {
          redirectTo("/auth/login-2");
        } else if (errorMessage === "Your account has been deactivated, Please contact ST&T team!" && pathName !== "/auth/login-2") {
          redirectTo('/');
        } else if (errorMessage === "Failed to authenticate token!") {
          if (pathName === "/paams") {
            console.log("Paams /");
            redirectTo("/auth/login-2");

          } else {
            redirectTo('/');
          }
        }

        throw error;
      }
    );
  }, [location.pathname]);

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Switch>
          <Route exact path="/" component={WebLayout} />
          <Route exact path="/:paams" component={WebLayout} />
          <Route path="/auth/login-2/email/forgot-password" component={Forget} />
          <Route path="/web" component={WebLayout} />
          <Route path={AUTH_PREFIX_PATH} render={(props) => <AuthLayout {...props} direction={direction} />} />
          <Route path={APP_PREFIX_PATH} render={(props) => <AppLayout {...props} direction={direction} />} />
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
