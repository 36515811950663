import React from 'react'
import { SIDE_NAV_WIDTH, SIDE_NAV_COLLAPSED_WIDTH, NAV_TYPE_TOP } from 'constants/ThemeConstant';
import { APP_NAME } from 'configs/AppConfig';
import { connect } from "react-redux";
import utils from 'utils';
import { Grid } from 'antd';
// import MainLogo from '../../assets/main_logo.png'
import MainLogoSm from '../../assets/main_logo_sm.png'
const MainLogo = '/img/stnt-logo-white.svg'
const paamsLogo = '/img/paams logo.png';
const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false
  if (isMobile && !props.mobileLogo) {
    return 0
  }
  if (isNavTop) {
    return 'auto'
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`
  } else {
    return `${SIDE_NAV_WIDTH}px`
  }
}

const adminDashboard = sessionStorage.getItem('adminDashboard');


const getLogo = (props) => {
  const { navCollapsed, logoType } = props;
  if (adminDashboard == 'stnt') {
    if (logoType === 'light') {
      if (navCollapsed) {
        return MainLogo;
      }
      return MainLogo;
    }
  }
  else {
    return paamsLogo;
  }

  if (navCollapsed) {
    return '/img/logo-sm.png'
  }


  // return MainLogo
}

const getLogoDisplay = (isMobile, mobileLogo) => {
  if (isMobile && !mobileLogo) {
    return 'd-none'
  } else {
    return 'logo'
  }
}

export const Logo = (props) => {
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  return (
    <div
      className={getLogoDisplay(isMobile, props.mobileLogo)}
      style={{
        width: `${getLogoWidthGutter(props, isMobile)}`,
        paddingTop: '40px',
        paddingBottom: "40px"
      }}>
      <img style={{ width: '150px' }} src={getLogo(props)} alt={`${APP_NAME} logo`} />
    </div>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType }
};

export default connect(mapStateToProps)(Logo);
