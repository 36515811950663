import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import Loading from 'components/shared-components/Loading';
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import Footer from 'components/layout-components/Footer';
import AppViews from 'views/app-views';
import WebViews from 'views/web-views';
import {
  Layout,
  Grid,
} from "antd";

import navigationConfig from "configs/NavigationConfig";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
  DIR_RTL,
  DIR_LTR
} from 'constants/ThemeConstant';
import utils from 'utils';
import { useThemeSwitcher } from "react-css-theme-switcher";
import axios from 'axios';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { getAddressDetails } from 'services/apiService';

const { Content } = Layout;
const { useBreakpoint } = Grid;

export const WebLayout = ({ navCollapsed, navType, location, direction }) => {
  const Location = useLocation();

  useEffect(async () => {
    try {
      const res = await getAddressDetails()
      if (res && Location.pathname === "/") {
        window.location.href = '/web/web_claimSubmission'
      }
      else if (res && Location.pathname === "/paams") {
        window.location.href = '/web/web_claimSubmission'
      }


    } catch (err) {
      // console.log(err.response)
    }
  }, [Location.pathname])

  return (
    <>
      <WebViews />
    </>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale }
};

export default connect(mapStateToProps)(React.memo(WebLayout));